//@ts-ignore
import Overseer from "@ledr/overseer";
import * as singleSpa from "single-spa";
import dotenv from "dotenv";

const apps = {
	"@ledr/layout": {
		apiName: "hgtp",
		needApi: false,
	},
	"@ledr/login": {
		logged: false,
		apiName: "hgtp",
		needApi: false,
	},
	"@ledr/navmenu": {
		logged: true,
		apiName: "hgtp",
		needApi: false,
	},
	"@ledr/tuto": {
		logged: true,
		route: "tuto",
		apiName: "hgtp",
		needApi: false,
	},
	"@ledr/browser": {
		logged: true,
		route: "browser",
		apiName: "hgtp",
		needApi: false,
	},
};

//@ts-ignore
const overseer = new Overseer(
	apps,
	singleSpa,
	//@ts-ignore
	window.env.LOGINMODE,
	//@ts-ignore
	window.env.AUTH,
);

/*
overseer.createEndpoint({
	name: "test",
	scheme: "wss",
	host: "wss.test.orchestra.ledr.io",
	port: 20059,
	sslPem: "/test.orchestra.ledr.io.pem"
})
*/

console.warn(
	{
	//@ts-ignore
	loginMode: window.env.LOGINMODE,
	//@ts-ignore
	AUTH: window.env.AUTH,
	name: "hgtp",
	//@ts-ignore
	scheme: window.env.API_DEFAULT_SCHEME,
	//@ts-ignore
	host: window.env.API_DEFAULT_HOST,
	//@ts-ignore
	port: window.env.API_DEFAULT_PORT,
	}
)
overseer.createEndpoint({
	name: "hgtp",
	//@ts-ignore
	scheme: window.env.API_DEFAULT_SCHEME,
	//@ts-ignore
	host: window.env.API_DEFAULT_HOST,
	//@ts-ignore
	port: window.env.API_DEFAULT_PORT,
})

overseer.start();

// Proxy could build composite api where each method can target different hosts
// api: new Proxy(apiA, {rewritedMethod: apiB.overloadMethod})

/* LOAD SOMETHING | THEN | RUN APP
System.import("@react-mf/styleguide").then(() => {
	start();
});


https://github.com/circlingthesun/systemjs-script-plugin
System.config({
	...
	meta: {
		"ga": {
			"scriptLoad": true,
			"format": "global",
			"exports": "ga"
		}
	},
	map: {
		"ga": "//www.google-analytics.com/analytics.js"
	}
});
 */
